import { vMount } from '@helpers';

// import Test from './components/Test.vue';

// vMount('#el', {
//     store,
//     render(h) {
//         return h(Test);
//     }
// });
import './circle-bg'
import './inputs'
const html = document.documentElement;

const getScroll = () => {
    let scroll = html.scrollTop;
    if(scroll > 50) {
        html.classList.add('has-scroll')
    } else {
        html.classList.remove('has-scroll')
    }
};
window.addEventListener('scroll', getScroll);

let burger = document.querySelectorAll('.js-burger');
if(burger) {
    for (let i = 0; i < burger.length; i++) {
        burger[i].addEventListener('click', hideMenu);
    }
}

function hideMenu(e) {
    e.preventDefault();
    if (html.classList.contains('has-open-nav')) {
        html.classList.remove('has-open-nav');
    } else {
        html.classList.add('has-open-nav');
    }
}

function closeMenu(e) {
    const target = e.target;
    if (html.classList.contains('has-open-nav') && !target.closest('.js-sidebar-menu') && !target.closest('.js-burger')) {
        html.classList.remove('has-open-nav');
    }
};
html.addEventListener('click', closeMenu);
html.addEventListener('touchend', closeMenu);

const $date = document.querySelector('.js-set-date');
if($date) { $date.innerHTML = 'notix &copy;&nbsp;' + new Date().getFullYear();}

window.addEventListener('load', function() {
    const _modal = document.querySelector('#js-notification');
    if(_modal) {
        setTimeout(function() {
            document.querySelector('.js-modal-triger').click();
        }, 1000)
    }
});
import Swiper from "swiper"

const sliderBrands = document.querySelector('.js-slider-brands');
if(sliderBrands) {
    new Swiper (sliderBrands, {
        slidesPerView: 4,
        spaceBetween: 20,
        loop: true,
        speed: 3000,
        autoplay: {
            delay: 0
        },
        breakpoints: {
            320: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 4
            },
            1660: {
                slidesPerView: 5
            }
        }
    });
};

const sliderFeedback = document.querySelector('.js-slider-feedback');
if(sliderFeedback) {
    let paginations = sliderFeedback.querySelector('.swiper-pagination');
    new Swiper (sliderFeedback, {
        slidesPerView: 3,
        lazy: true,
        pagination: {
            el: paginations,
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1.1
            },
            767.98: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 3
            }
        }
    });
};

const sliderBlog = document.querySelector('.js-slider-blog');
if(sliderBlog) {
    let paginations = sliderBlog.querySelector('.swiper-pagination');

    new Swiper (sliderBlog, {
        slidesPerView: 4.1,
        pagination: {
            el: paginations,
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView: 1.2
            },
            600: {
                slidesPerView: 2.2
            },
            992: {
                slidesPerView: 3.1
            },
            1500: {
                slidesPerView: 4.1
            }
        }
    });
};
import datepicker from 'js-datepicker'

let inputDate = document.querySelectorAll('.js-date');
if(inputDate) {
    inputDate.forEach(item => {
        datepicker(item, {
            customOverlayMonths: [
                'Січень', ' Лютий', 'Березень', 'Квітень', 'Травень', 'Червень', 'Липень', 'Серпень', 'Вересень', 'Жовтень', 'Листопад', 'Грудень'
            ],
            customDays: [
                'Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'
            ],
            showAllDates: true,
            formatter: (input, date) => {
                const value = date.toLocaleDateString()
                input.value = value
              }
        })
    })
}
// import * as L from 'leaflet';

// const mapId = document.querySelector('.js-map');
// function initMap(){
//     let options = {
//         center:         [50.759110, 25.352639],
//         zoom:           16,
//         scrollWheelZoom: false
//     };
//     let path = require('../../../img/marker.png').default;
//     if(window.markers) {
//         path = window.markers;
//     };
//     let map = L.map(mapId, options);
//     let icon = L.icon({
//         iconUrl: path,
//         iconSize: [21, 28],
//         iconAnchor: [0, 0]
//     });
    
//     L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png')
//         .addTo(map);

//     L.marker([50.759110, 25.352639], {icon})
//         .addTo(map);
// };

// if(mapId) {
//     initMap();
// };
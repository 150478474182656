let media = window.matchMedia('(min-width: 991px)'),
    circleParent = document.querySelectorAll('.js-bg'),
    event = event || window.event;


let checkMedia = (media) => {
    if (circleParent) {
        Array.from(circleParent, item => {
            let bg,
                width,
                top,
                left;
            if(media.matches) {
                item.addEventListener('mouseenter', function(event) {
                    bg = this.querySelector('.js-circle'),
                    width = bg.offsetWidth;
                    top = (event.pageY - this.offsetTop + 1) - width / 2,
                    left = (event.pageX - this.offsetLeft + 1) - width / 2;
                    bg.setAttribute(
                        "style",
                        `transform: translate(${left}px, ${top}px) scale(1, 1);
                    `);
                });
                item.addEventListener('mouseleave', function(event) {
                    bg = this.querySelector('.js-circle'),
                    width = bg.offsetWidth;
                    top = (event.pageY - this.offsetTop + 1) - width / 2,
                    left = (event.pageX - this.offsetLeft + 1) - width / 2;
                    bg.setAttribute(
                        "style",
                        `transform: translate(${left}px, ${top}px) scale(0, 0);
                    `);
                });
            }
            else {
                item.removeEventListener('mouseenter', () =>{});
                item.removeEventListener('mouseleave', () =>{});
            }
        });
    }
};

if (circleParent) {
    checkMedia(media)
    media.addListener(checkMedia)
};